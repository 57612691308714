import {BREAKPOINT} from 'aac-components/utils/styles';

const BlockFullWidth = (props) => {
    const {block_title = '', block_content = '', use_background_gradient = true} = props;
    return (
        <div className={`block ${use_background_gradient ? 'gradient' : ''}`}>
            <h2 dangerouslySetInnerHTML={{__html: block_title}} />
            <div
                className="block__content"
                dangerouslySetInnerHTML={{__html: block_content}}
            />
            <style jsx>
                {`
                    h2 {
                        text-align: center;
                    }
                    .block {
                        padding: 40px 16px;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .block {
                            padding: 40px 0;
                            margin-bottom: 64px;
                        }
                    }
                    .block.gradient {
                        background: linear-gradient(
                            103.99deg,
                            rgba(244, 249, 249, 0.4) 0%,
                            rgba(248, 238, 229, 0.4) 100%
                        );
                        border-radius: 16px;
                    }
                `}
            </style>
        </div>
    );
};
export default BlockFullWidth;
